<template>
  <q-slider
    v-model="model"
    :min="$attrs.min || 0"
    :max="$attrs.max || 100"
    label
  />
</template>

<script setup>
const model = defineModel({
  type: Number,
  default: () => 0,
  required: true,
});
</script>
