<template>
  <MeetingModule :loading>
    <div class="tw-flex tw-flex-col tw-gap-4">
      <c-label
        :label="`Selecteer alleen docenten die ten minste ${selectedTeacherMentorclassThreshold}% van de leerlingen uit een mentorklas in éénzelfde lesgroep heeft`"
      />

      <c-slider
        class="tw-mt-8"
        v-model="selectedTeacherMentorclassThreshold"
        :label-value="`${selectedTeacherMentorclassThreshold}%`"
        label-always
        snap
        :marker-labels="visibleMarkersArray"
        :disable="!isEditable"
      />

      <Transition>
        <c-banner
          v-show="selectedTeacherMentorclassThreshold < 50"
          class="tw-max-w-[70%] tw-text-balance"
          type="warning"
          >Weet je zeker dat je ook docenten wilt uitnodigen die minder dan 50%
          van de leerlingen uit deze mentorklas in dezelfde lesgroep hebben? Zij
          hebben een beperkter beeld van de totale groepsdynamiek, en kan de
          resultaten vertekenen.</c-banner
        >
      </Transition>

      <c-button
        :disabled="!hasChangedTreshold"
        :loading="isApplyingThreshold"
        class="tw-self-start"
        label="Percentage toepassen"
        size="small"
        :title="
          !hasChangedTreshold
            ? 'Dit percentage is al toegepast'
            : 'Percentage toepassen'
        "
        @click="tresholdChanged"
      />

      <div class="tw-flex tw-flex-col">
        <c-field
          v-model="modelFlatMap"
          :rules="[required, min(1)]"
          :lazy-rules="true"
          hide-bottom-space
        >
          <q-tabs
            v-model="selectedTab"
            dense
            active-color="primary"
            indicator-color="primary"
            align="justify"
          >
            <q-tab
              v-for="{ name, teachers } of selectedMentorClassesAndTeachers"
              :name="name"
            >
              <div class="tw-flex tw-gap-2">
                <span class="tw-font-bold">{{ name }}</span>
                ({{ model.teacher_ids[name].length }}/{{ teachers.length }})
              </div>
            </q-tab>
          </q-tabs>

          <q-separator />

          <q-tab-panels class="!tw-bg-gray-50" v-model="selectedTab">
            <q-tab-panel
              class="tw-grid tw-grid-cols-12 tw-gap-2 !tw-p-2"
              v-for="{ name, teachers } of selectedMentorClassesAndTeachers"
              :name="name"
            >
              <c-banner v-if="!teachers.length" class="tw-col-span-full">
                <span class="tw-font-bold">{{ name }}</span> heeft geen docenten
                die les geven aan ten minste
                <span class="tw-font-bold"
                  >{{ model.teacher_percentage }}%</span
                >
                van de leerlingen
              </c-banner>

              <div
                class="tw-col-span-2"
                v-for="{
                  id,
                  teacherShortage,
                  mentorClass,
                  percentage,
                } in teachers"
              >
                <c-checkbox
                  :disable="!isEditable"
                  :model-value="model.teacher_ids[mentorClass]"
                  :val="id"
                  @update:model-value="updateTeacherIds(id, mentorClass)"
                >
                  <span
                    class="tw-flex tw-items-center tw-gap-2 tw-overflow-hidden tw-truncate tw-text-ellipsis tw-font-bold"
                  >
                    {{ teacherShortage }}
                    <span class="tw-font-normal"
                      >{{ Math.floor(percentage) }}%
                    </span>
                  </span>
                </c-checkbox>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </c-field>
      </div>
    </div>
  </MeetingModule>
</template>

<script setup>
import { computed, onMounted, ref, watchEffect } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import { storeToRefs } from "pinia";
import { useMeetingStore } from "store/modules/meetingStore";
import { createSteppedArray } from "composables/useHelpers.js";
import { useQuasar } from "quasar";
import { required, min } from "common/validationRules.js";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

// On model update
const modelUpdate = (value) => {
  if (!value) {
    return (isStepCompleted.value = false);
  }

  isStepCompleted.value = modelFlatMap.value.length > 0;
};

const modelFlatMap = computed(() => {
  return Object.values(model.value.teacher_ids).flatMap((teacher) => teacher);
});

// Quasar
const $q = useQuasar();

// Meeting store
const meetingStore = useMeetingStore();
const { fetchTeachers } = meetingStore;
const { isEditable, teachers } = storeToRefs(meetingStore);

// Refs
const loading = ref(true);
const selectedTab = ref(null);
const isApplyingThreshold = ref(false);
const teacherWorker = ref(
  new Worker(new URL("workers/teacherWorker.js", import.meta.url), {
    type: "module",
  }),
);
const selectedTeacherMentorclassThreshold = ref(50);

const hasChangedTreshold = computed(() => {
  return (
    model.value.teacher_percentage !== selectedTeacherMentorclassThreshold.value
  );
});

const tresholdChanged = () => {
  try {
    isApplyingThreshold.value = true;

    model.value.teacher_percentage = selectedTeacherMentorclassThreshold.value;

    teacherWorker.value.postMessage(selectedMentorClassesAndTeachers.value);

    teacherWorker.value.onmessage = ({ data }) => {
      model.value.teacher_ids = data;

      $q.notify({
        message: "Percentage toegepast",
        color: "positive",
      });

      isApplyingThreshold.value = false;
    };

    teacherWorker.onerror = (error) => {
      console.error("Worker error:", error);

      $q.notify({
        message:
          "Er ging iets mis met het toepassen van het percentage, probeer het opnieuw",
        color: "negative",
      });

      isApplyingThreshold.value = false;
    };
  } catch (error) {
    console.log("🚀 ~ tresholdChanged ~ error:", error);
  }
};

const updateTeacherIds = (teacherId, mentorClass) => {
  // Remove the teacher from the specific mentorClass when it's already selected
  if (model.value.teacher_ids[mentorClass].includes(teacherId)) {
    return (model.value.teacher_ids[mentorClass] = model.value.teacher_ids[
      mentorClass
    ].filter((_teacherId) => _teacherId !== teacherId));
  }

  model.value.teacher_ids[mentorClass].push(teacherId);
};

const selectedMentorClassesAndTeachers = computed(() => {
  if (!model.value?.mentor_classes || !model.value?.mentor_classes.length) {
    return [];
  }

  const mentorClasses = model.value.mentor_classes
    .sort((a, b) => a.localeCompare(b))
    .map((mentorClass) => {
      return {
        name: mentorClass,
        teachers: teacherByMentorClassAndTreshold(
          mentorClass,
          model.value.teacher_percentage,
        ).sort((a, b) => {
          if (a.percentage === b.percentage) {
            return a.teacherShortage.localeCompare(b.teacherShortage);
          }

          return b.percentage - a.percentage;
        }),
      };
    });

  if (
    selectedTab.value === null ||
    !mentorClasses.flatMap(({ name }) => name).includes(selectedTab.value)
  ) {
    const [firstClass] = mentorClasses;
    selectedTab.value = firstClass.name;
  }

  // Remove mentor classes that are deselected
  updateSelectedMentorClasses();

  return mentorClasses;
});

const updateSelectedMentorClasses = () => {
  // Remove mentor classes that are deselected
  model.value.teacher_ids = Object.fromEntries(
    Object.entries(model.value.teacher_ids).filter(([key]) =>
      model.value.mentor_classes.includes(key),
    ),
  );

  // Add mentor class to selection list
  model.value.mentor_classes.forEach((name) => {
    if (!model.value.teacher_ids?.[name]) {
      model.value.teacher_ids[name] = teacherByMentorClassAndTreshold(
        name,
        model.value.teacher_percentage,
      ).map(({ id }) => id);
    }
  });
};

const teacherByMentorClassAndTreshold = (mentorClass, treshold) => {
  return teachers.value.reduce((acc, current) => {
    if (!current.lessongroups.length) {
      return acc;
    }

    current.lessongroups.forEach(({ mentor_classes }) => {
      if (!mentor_classes || !Array.isArray(mentor_classes)) {
        return acc;
      }

      const found = mentor_classes.find(
        ({ name }) =>
          name.toString().toLowerCase() ===
          mentorClass.toString().toLowerCase(),
      );

      if (!found || found.percentage < treshold) {
        return acc;
      }

      const { percentage, name: mentorClassName } = found;

      acc.push({
        teacherShortage: current.teacher_shortage,
        id: current.teacher_id,
        percentage,
        mentorClass: mentorClassName,
      });
    });

    return acc;
  }, []);
};

// All values in this array will be visible in the stepper
const visibleMarkersArray = ref(createSteppedArray(0, 100, 10));

onMounted(async () => {
  await fetchTeachers();

  loading.value = false;

  // Define the default model for teacher_ids
  if (model.value?.teacher_ids == null) {
    model.value.teacher_ids = {};
  }

  // Define the default model for teacher_percentage
  if (model.value?.teacher_percentage == null) {
    model.value.teacher_percentage = 50;
  }

  // Set the initial selected threshold as the current value
  selectedTeacherMentorclassThreshold.value = model.value.teacher_percentage;

  modelUpdate(model.value.teacher_ids);
});

watchEffect(() => {
  modelUpdate(model.value.teacher_ids);
});
</script>

<style lang="scss">
// TODO: Create a wrapper for tabs and table components
// TODO: Move this styling to their respective components
.q-tabs {
  .q-tab__icon {
    @apply tw-rounded tw-bg-white tw-text-4xl;
  }
}

.q-table__container {
  .q-table__top {
    @apply tw-py-0;
  }

  table.q-table {
    @apply tw-border-t;
  }

  &.q-table--cell-separator {
    .q-table__top {
      @apply tw-border-b-0;
    }

    tbody {
      tr:nth-child(odd),
      tr:nth-child(odd) td {
        @apply tw-bg-gray-50 #{!important};
      }
    }
  }

  &.sticky-column.sticky-header {
    td:first-child {
      @apply tw-bg-white;
    }

    tr th {
      @apply tw-sticky tw-z-[2] tw-bg-white;
    }

    thead {
      tr:last-child th {
        @apply tw-z-[3];
        top: 48px;
      }

      tr:first-child th {
        @apply tw-z-[1];
        top: 0;
      }
    }

    tr:first-child th:first-child {
      @apply tw-z-[3];
    }

    td:first-child {
      @apply tw-z-[1];
    }

    td:first-child,
    th:first-child {
      @apply tw-sticky;
      left: 0;
    }

    tbody {
      scroll-margin-top: 48px;
    }
  }

  &.sticky-column {
    thead tr:first-child th:first-child {
      @apply tw-bg-white;
    }

    td:first-child {
      @apply tw-bg-white;
    }

    th:first-child,
    td:first-child {
      @apply tw-sticky tw-z-[1];
      left: 0;
    }
  }

  &.sticky-header {
    max-height: 600px;

    .q-table__top,
    .q-table__bottom,
    thead tr:first-child th {
      @apply tw-bg-white;
    }

    thead tr th {
      @apply tw-sticky tw-z-[3];
    }

    thead tr:first-child th {
      top: 0;
    }

    &.q-table--loading thead tr:last-child th {
      top: 48px;
    }

    tbody {
      scroll-margin-top: 48px;
    }
  }
}
</style>
