<template>
  <MeetingModule>
    <c-field
      v-model="model.mentor_classes"
      :rules="[required, min(1)]"
      :lazy-rules="true"
      hide-bottom-space
    >
      <div class="tw-grid tw-grid-cols-12 tw-gap-2">
        <div class="tw-col-span-full">
          <c-checkbox
            :model-value="allClassesSelected"
            :label="toggleLabel"
            @click="toggleSelectAll"
          />
        </div>

        <div
          class="tw-col-span-6 md:tw-col-span-3 lg:tw-col-span-2"
          v-for="mentorClass in mentorClasses"
          :key="mentorClass"
        >
          <c-checkbox
            :val="mentorClass"
            v-model="model.mentor_classes"
            :label="mentorClass"
            @update:model-value="modelUpdate"
          />
        </div>
      </div>
    </c-field>
  </MeetingModule>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref, computed, watchEffect } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";
import useHelpers from "composables/useHelpers";
import { required, min } from "common/validationRules.js";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});
const isStepCompleted = defineModel("isStepCompleted");

// On model update
const modelUpdate = (value) => {
  isStepCompleted.value = value && value.length > 0;
};

// Helpers
const { schoolId } = useHelpers();

// Refs
const mentorClasses = ref([]);

// TODO: To store
const getMentorClasses = async () => {
  try {
    const { data } = await axios.get(`api/school/${schoolId}/mentor-classes`);

    mentorClasses.value = Object.values(data);
  } catch (error) {
    console.log("🚀 ~ getMentorClasses ~ error:", error);
  }
};

// Return boolean if all classes are selected
const allClassesSelected = computed(() => {
  if (model.value.mentor_classes) {
    return model.value.mentor_classes.length === mentorClasses.value.length;
  }
});

const toggleLabel = computed(() => {
  return allClassesSelected.value
    ? "Alle klassen deselecteren"
    : "Alle klassen selecteren";
});

const toggleSelectAll = () => {
  if (allClassesSelected.value) {
    model.value.mentor_classes = [];
  } else {
    model.value.mentor_classes = mentorClasses.value.map((item) => item);
  }
};

onMounted(async () => {
  await getMentorClasses();

  if (model.value?.mentor_classes == null) {
    model.value.mentor_classes = [];
  }

  modelUpdate(model.value.mentor_classes);
});

watchEffect(() => {
  modelUpdate(model.value.mentor_classes);
});
</script>
