import { acceptHMRUpdate, defineStore, storeToRefs } from "pinia";
import { useSettingsStore } from "store/modules/settingStore.js";
import { computed, ref } from "vue";

export const useModuleStore = defineStore("module", () => {
  const settingStore = useSettingsStore();
  const { schoolSettings, gradesEnabled } = storeToRefs(settingStore);

  const allModules = ref([
    "studentMeeting",
    "portfolio",
    "transitionMeeting",
    "learningGoals",
  ]);

  const hasSettingEnabled = (module) => {
    switch (module) {
      case "transitionMeeting":
        // Always available for all schools
        return true;
      case "groupMeeting":
        return schoolSettings.value.root_settings.group_meeting;
      case "studentMeeting":
        return schoolSettings.value.student_meeting_settings.enabled;
      case "portfolio":
        return schoolSettings.value.portfolio_settings.enabled;
      case "learningGoals":
        return false;
      case "manageData":
        return !schoolSettings.value.school_settings.manage_data;
      case "gradesEnabled":
        return gradesEnabled.value;
    }
  };

  const mapModuleName = (module) => {
    switch (module) {
      case "transitionMeeting":
        return "overgangsbespreking";
      case "studentMeeting":
        return "leerlingbespreking";
      case "portfolio":
        return "portfolio";
      case "learningGoals":
        return "leerdoelen";
    }
  };

  const activeModules = computed(() => {
    // Use the Array.filter() method to filter out the modules that are not enabled
    const enabledModules = allModules.value.filter((module) =>
      hasSettingEnabled(module),
    );

    return enabledModules;
  });

  return { hasSettingEnabled, activeModules, mapModuleName };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModuleStore, import.meta.hot));
}
