import axios from "axios";
import { defineStore } from "pinia";
import { useQuasar } from "quasar";
import { computed, reactive, ref } from "vue";
import useHelpers from "composables/useHelpers";
import { shouldFetch } from "common/fetchHelpers.js";
import { cloneDeep } from "lodash";

export const useSettingsStore = defineStore("settings", () => {
  // Quasar
  const $q = useQuasar();

  const { schoolId } = useHelpers();

  // Refs
  const initialSchoolSettings = reactive({});
  const initialRestrictedSchoolSettings = reactive({});
  const schoolSettings = reactive({});
  const restrictedSchoolSettings = reactive({});
  const initialUserSettings = reactive({});
  const userSettings = reactive({});
  const fetchingData = ref(false);
  const updatingData = ref(false);
  const schools_without_new_processing_agreement = ref([]);
  const educationLevels = ref([]);
  const portfolioTags = ref([]);
  const initialPortfolioTags = ref([]);
  const portfolioSkills = ref([]);
  const initialPortfolioSkills = ref([]);
  const portfolioCategories = ref([]);
  const initialPortfolioCategories = ref([]);
  const ratingTypes = ref([]);
  const initialRatingTypes = ref([]);

  const hasFetched = ref({
    ratingTypes: false,
  });

  const emailFormList = ref([
    {
      key: "email_sync",
      label: "Synchronisatie fouten",
    },
    {
      key: "email_help",
      label: "Help formulieren",
    },
    {
      key: "email_license",
      label: "Licentie berichten",
    },
  ]);

  // Returns boolean when use categories for skills is enabled
  const usePortfolioCategories = computed(() => {
    return initialSchoolSettings.portfolio_settings.settings
      .allow_skill_categories;
  });

  // Check if grades are enabled for the school.
  const gradesEnabled = computed(() => {
    return initialSchoolSettings.student_meeting_settings.settings
      .enable_se_grade ||
      initialSchoolSettings.student_meeting_settings.settings
        .enable_rapport_grade
      ? true
      : false;
  });

  // Returns a string of  the allowed file types for the portfolio
  const allowedFileTypes = computed(() => {
    const { disallowed_file_types } =
      initialSchoolSettings.portfolio_settings.settings;

    const allowedTypes = disallowed_file_types.filter((type) => type.allowed);

    return allowedTypes.map((type) => type.label).join(", ");
  });

  // Show banners in the settings.
  const portfolioBanners = computed(() => {
    return [
      {
        type: "warning",
        message: `Categorieën staan ingeschakeld maar er zijn nog vaardigheden zonder categorie. Koppel hier een categorie aan onder "vaardigheden beheren".`,
        show:
          usePortfolioCategories.value &&
          portfolioSkills.value.some((skill) => skill.category_id === null),
      },
    ];
  });

  // Methods
  const formatSchoolEmailData = (email_settings) => {
    const emails = [];

    const emailsByType = emailFormList.value
      .flatMap(({ key }) => key)
      .map((type) => ({
        type,
        emails: !email_settings[type]
          ? []
          : [
              ...new Set(
                email_settings[type].split(",").map((email) => email.trim()),
              ),
            ],
      }));

    emailsByType.forEach(({ type, emails: _emails }) => {
      _emails.forEach((email) => {
        const found = emails.find(({ email: _email }) => _email === email);

        if (found) {
          return !found.enabledCategories.includes(type)
            ? found.enabledCategories.push(type)
            : null;
        }

        return emails.push({
          email,
          enabledCategories: [type],
        });
      });
    });

    return emails;
  };

  // Actions
  /**
   * Fetch the school settings by school id
   * @param {number, null} id
   * @param forceRefetch
   * @returns {any}
   */
  const getSchoolSettings = async (id = null, forceRefetch = false) => {
    id = id || useHelpers().schoolId;

    if (Object.keys(schoolSettings).length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const {
        data: { data: schoolData },
      } = await axios.get(`/api/school/${id}/settings`);

      schoolData.formatted_email_settings = formatSchoolEmailData(
        schoolData.email_settings,
      );

      Object.assign(schoolSettings, schoolData);

      // Store the initial school settings before updating without Object reference
      Object.assign(
        initialSchoolSettings,
        JSON.parse(JSON.stringify(schoolData)),
      );

      return schoolData;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Fetch the restricted school settings by school id
   * @returns {any}
   * @param id {number},  forceRefetch {boolean}
   */
  const getRestrictedSchoolSettings = async (id, forceRefetch = false) => {
    id = id || useHelpers().schoolId;

    if (Object.keys(restrictedSchoolSettings).length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const {
        data: { data: schoolData },
      } = await axios.get(`/api/schools/${id}`);

      Object.assign(restrictedSchoolSettings, schoolData);

      // Store the initial school settings before updating without Object reference
      Object.assign(
        initialRestrictedSchoolSettings,
        JSON.parse(JSON.stringify(schoolData)),
      );

      return schoolData;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Get the current authenticated user settings
   * @returns {any}
   */
  const getUserSettings = async () => {
    if (Object.keys(userSettings).length !== 0) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(`/api/settings`);

      Object.assign(userSettings, data.data);
      Object.assign(initialUserSettings, JSON.parse(JSON.stringify(data.data)));

      return userSettings;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Get the education levels
   * @returns {any}
   */
  const getEducationLevels = async () => {
    if (Object.keys(educationLevels.value).length !== 0) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/education-levels`,
      );

      Object.assign(educationLevels.value, data);

      return educationLevels.value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Get the portfolio tags
   * @returns {any}
   */
  const getPortfolioTags = async (options = {}) => {
    const { forceRefetch = false } = options;

    if (portfolioTags.value.length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/portfolio-tags`,
      );

      portfolioTags.value = data.data;
      initialPortfolioTags.value = JSON.parse(JSON.stringify(data.data));

      return portfolioTags;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  const fetchRatingTypes = async (forceFetch = false) => {
    const { schoolId } = useHelpers();

    if (!shouldFetch(hasFetched.value["ratingTypes"]) && !forceFetch) {
      return ratingTypes.value || [];
    }

    try {
      const {
        data: { data: fetchedRatingTypes },
      } = await axios.get(`/api/school/${schoolId}/rating-types`);

      hasFetched.value["ratingTypes"] = new Date();

      ratingTypes.value = fetchedRatingTypes;
      initialRatingTypes.value = cloneDeep(ratingTypes.value);

      return ratingTypes.value;
    } catch (error) {
      console.log("🚀 ~ fetchTeachers ~ error:", error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  const storeRatingType = async (rating) => {
    console.log("🚀 ~ storeRatingType ~ rating:", rating);
    try {
      const { data } = await axios.post(
        `/api/school/${schoolId}/rating-types`,
        rating,
      );

      console.log("🚀 ~ storeRatingType ~ data:", data);

      await fetchRatingTypes(true);

      // initialPortfolioTags.value.push(data);
      // portfolioTags.value.push(data);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    }
  };

  const updateRatingType = async (ratingType) => {
    try {
      updatingData.value = true;

      const { id } = ratingType;

      const { data } = await axios.put(
        `/api/school/${schoolId}/rating-types/${id}`,
        ratingType,
      );

      const found = ratingTypes.value.find(({ id: _id }) => _id === id);
      const foundInitial = initialRatingTypes.value.find(
        ({ id: _id }) => _id === id,
      );

      if (found) {
        Object.assign(found, data);
      }

      if (foundInitial) {
        Object.assign(foundInitial, data);
      }

      return data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  const deleteRatingType = async (id) => {
    try {
      await axios.delete(`/api/school/${schoolId}/rating-types/${id}`);

      return await fetchRatingTypes(true);
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    }
  };

  /**
   * add portfolio tags
   * @param key
   * @param val
   * @returns {Promise<any>}
   */
  const addPortfolioTags = async ({ name }) => {
    try {
      updatingData.value = true;

      const { data } = await axios.post(
        `/api/school/${schoolId}/portfolio-tags`,
        {
          name,
        },
      );

      initialPortfolioTags.value.push(data);
      portfolioTags.value.push(data);

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        Object.assign(
          portfolioTags,
          JSON.parse(JSON.stringify(initialPortfolioTags)),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  const updatePortfolioTagEducationLevels = async (tag) => {
    const { id, education_levels } = tag;

    try {
      await axios.post(
        `/api/school/${schoolId}/portfolio-tags/${id}/connect-education-levels`,
        { education_levels },
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  const updatePortfolioSkillsEducationLevels = async (skill) => {
    const { id, education_levels } = skill;

    try {
      const { data } = await axios.post(
        `/api/school/${schoolId}/portfolio-skills/${id}/connect-education-levels`,
        { education_levels },
      );

      console.log("🚀 ~ updatePortfolioSkillsEducationLevels ~ data:", data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    }
  };

  /**
   *
   * @param key
   * @param val
   * @returns {Promise<any>}
   */
  const updatePortfolioTag = async ({ id, name }) => {
    try {
      updatingData.value = true;

      const { data } = await axios.put(
        `/api/school/${schoolId}/portfolio-tags/${id}`,
        {
          id,
          name,
        },
      );

      const found = portfolioTags.value.find(
        ({ id: portfolioId }) => portfolioId === id,
      );

      if (found) {
        Object.assign(found, data);
      }

      return data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioTags.value = JSON.parse(
          JSON.stringify(initialPortfolioTags.value),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   *
   * @param key
   * @returns {Promise<any>}
   */
  const deletePortfolioTag = async ({ id }) => {
    try {
      updatingData.value = true;

      const response = await axios.delete(
        `/api/school/${schoolId}/portfolio-tags/${id}`,
        {
          id,
        },
      );

      portfolioTags.value = response.data.data;
      initialPortfolioTags.value = JSON.parse(
        JSON.stringify(response.data.data),
      );

      return response.data.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Get the portfolio skills
   * @returns {any}
   */
  const getPortfolioSkills = async (options = {}) => {
    const { forceRefetch = false } = options;

    if (portfolioSkills.value.length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/portfolio-skills`,
      );

      data.data.forEach((skill) => {
        if (skill.education_levels === null) {
          skill.education_levels = [];
        }
      });

      portfolioSkills.value = data.data;
      initialPortfolioSkills.value = JSON.parse(JSON.stringify(data.data));

      return portfolioSkills;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * add portfolio skills
   * @param key
   * @returns {Promise<any>}
   */
  const addPortfolioSkills = async (skill) => {
    try {
      updatingData.value = true;

      const { data } = await axios.post(
        `/api/school/${schoolId}/portfolio-skills`,
        skill,
      );

      console.log(
        "🚀 ~ addPortfolioSkills ~ portfolioSkills.value:",
        portfolioSkills.value,
      );
      // Add the new skill to portfolioSkills
      portfolioSkills.value.push(data);

      // Keep the initial and current value in sync
      initialPortfolioSkills.value = JSON.parse(
        JSON.stringify(portfolioSkills.value),
      );

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioSkills.value = initialPortfolioSkills.value;

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   *
   * @param key
   * @param val
   * @returns {Promise<any>}
   */
  const updatePortfolioSkill = async ({
    id,
    name,
    description,
    category_id,
  }) => {
    const updateObject = {
      id,
      name,
      description,
    };

    if (usePortfolioCategories.value) {
      updateObject.category_id = category_id;
    }

    try {
      updatingData.value = true;

      const response = await axios.put(
        `/api/school/${schoolId}/portfolio-skills/${id}`,
        {
          ...updateObject,
        },
      );

      return response.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioSkills.value = JSON.parse(
          JSON.stringify(initialPortfolioSkills.value),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   *
   * @param key
   * @returns {Promise<any>}
   */
  const deletePortfolioSkill = async ({ id }) => {
    try {
      updatingData.value = true;

      const response = await axios.delete(
        `/api/school/${schoolId}/portfolio-skills/${id}`,
        {
          id,
        },
      );

      portfolioSkills.value = response.data.data;
      initialPortfolioSkills.value = JSON.parse(
        JSON.stringify(response.data.data),
      );

      return response.data.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Get the portfolio categories
   * @returns {any}
   */
  const getPortfolioCategories = async (options = {}) => {
    const { forceRefetch = false } = options;

    if (portfolioCategories.value.length !== 0 && !forceRefetch) {
      return;
    }

    try {
      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/portfolio-skill-categories`,
      );

      portfolioCategories.value = data.data;
      initialPortfolioCategories.value = JSON.parse(JSON.stringify(data.data));

      return portfolioCategories;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * add portfolio category
   * @param key
   * @returns {Promise<any>}
   */
  const addPortfolioCategory = async ({ name }) => {
    try {
      updatingData.value = true;

      const response = await axios.post(
        `/api/school/${schoolId}/portfolio-skill-categories`,
        {
          name,
        },
      );

      portfolioCategories.value.push(response.data);
      initialPortfolioCategories.value.push(
        JSON.parse(JSON.stringify(response.data)),
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioCategories.value = initialPortfolioCategories.value;

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   *
   * @param key
   * @returns {Promise<any>}
   */
  const updatePortfolioCategory = async ({ id, name }) => {
    try {
      updatingData.value = true;

      const response = await axios.put(
        `/api/school/${schoolId}/portfolio-skill-categories/${id}`,
        {
          id,
          name,
        },
      );

      return response.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        portfolioCategories.value = JSON.parse(
          JSON.stringify(initialPortfolioCategories.value),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   *
   * @param key
   * @returns {Promise<any>}
   */
  const deletePortfolioCategory = async ({ id }) => {
    try {
      updatingData.value = true;

      const response = await axios.delete(
        `/api/school/${schoolId}/portfolio-skill-categories/${id}`,
        {
          id,
        },
      );

      portfolioCategories.value = response.data.data;
      initialPortfolioCategories.value = JSON.parse(
        JSON.stringify(response.data.data),
      );

      return response.data.data;
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   *
   * @param key
   * @param val
   * @returns {Promise<any>}
   */
  const updateUserSettings = async ({ key, val }) => {
    try {
      updatingData.value = true;

      const response = await axios.put(`/api/settings`, {
        field: key,
        value: val,
      });

      // Update initialSchoolSettings as it's succesfully updated
      Object.assign(
        initialUserSettings,
        JSON.parse(JSON.stringify(response.data.data)),
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        Object.assign(
          userSettings,
          JSON.parse(JSON.stringify(initialUserSettings)),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Get the schools without new processing agreement
   * @returns {Promise<{length}|T|any>}
   */
  const getSchoolsWithoutNewProcessingAgreement = async () => {
    try {
      if (schools_without_new_processing_agreement.value.length) {
        return schools_without_new_processing_agreement.value;
      }

      fetchingData.value = true;

      const { data } = await axios.get(
        `/api/school/${schoolId}/temporary-school-grades-table`,
      );

      schools_without_new_processing_agreement.value = data;

      return data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
        return Promise.reject(error.response);
      }
    } finally {
      fetchingData.value = false;
    }
  };

  /**
   * Check if the school has a new processing agreement
   * @type {ComputedRef<boolean>}
   */
  const schoolHasNewProcessingAgreement = computed(() => {
    return !schools_without_new_processing_agreement.value.includes(
      parseInt(schoolId),
    );
  });

  /**
   * Update the school settings
   * @returns {any}
   * @param key
   * @param val
   * @param root
   */
  const updateSettings = async (key, val, root = false) => {
    try {
      updatingData.value = true;

      let endpoint = `/api/school/${schoolId}/settings`;

      if (root) {
        endpoint = `/api/school/${schoolId}/settings/root`;
      }

      const response = await axios.put(endpoint, {
        field: key,
        value: val,
      });

      // Update initialSchoolSettings as it's succesfully updated
      Object.assign(
        initialSchoolSettings,
        JSON.parse(JSON.stringify(response.data.data)),
      );

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        // Set the value back because the request failed
        Object.assign(
          schoolSettings,
          JSON.parse(JSON.stringify(initialSchoolSettings)),
        );

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * Update the school's auth_source
   * @param {number|string} value
   * @returns {any}
   */
  const updateAuthSource = async (value) => {
    try {
      updatingData.value = true;

      let formData = new FormData();
      formData.set("sso_authsource", value);

      await axios.post(
        `/api/school/${schoolSettings.school_id}/update-authsource`,
        formData,
      );

      restrictedSchoolSettings.sso_authsource = value;

      // Update initialSchoolSettings as it's succesfully updated
      initialRestrictedSchoolSettings.sso_authsource = value;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);

        return Promise.reject(error.response);
      }
    } finally {
      updatingData.value = false;
    }
  };

  /**
   * This will update the email settings
   * @param {string} type
   * @param {string} value
   * @returns {any}
   */
  const updateEmailSettings = async (type, value) => {
    const formData = new FormData();
    formData.set("type", type);
    formData.set("value", value);

    try {
      updatingData.value = true;

      await axios.post(`/api/school/${schoolId}/email-settings`, formData);

      return Promise.resolve();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return Promise.reject(error);
      }
    } finally {
      updatingData.value = false;
    }
  };

  return {
    schoolSettings,
    restrictedSchoolSettings,
    initialSchoolSettings,
    initialRestrictedSchoolSettings,
    userSettings,
    fetchingData,
    updatingData,
    storeRatingType,
    deleteRatingType,
    updateRatingType,
    usePortfolioCategories,
    emailFormList,
    initialPortfolioTags,
    fetchRatingTypes,
    ratingTypes,
    initialRatingTypes,
    educationLevels,
    portfolioTags,
    portfolioSkills,
    initialPortfolioSkills,
    portfolioCategories,
    initialPortfolioCategories,
    portfolioBanners,
    initialUserSettings,
    getSchoolSettings,
    getPortfolioTags,
    getPortfolioCategories,
    addPortfolioCategory,
    updatePortfolioCategory,
    deletePortfolioCategory,
    getRestrictedSchoolSettings,
    getEducationLevels,
    getUserSettings,
    updateUserSettings,
    updatePortfolioTag,
    deletePortfolioTag,
    getSchoolsWithoutNewProcessingAgreement,
    addPortfolioTags,
    schoolHasNewProcessingAgreement,
    updateSettings,
    updateEmailSettings,
    updateAuthSource,
    getPortfolioSkills,
    addPortfolioSkills,
    updatePortfolioSkill,
    deletePortfolioSkill,
    updatePortfolioTagEducationLevels,
    updatePortfolioSkillsEducationLevels,
    allowedFileTypes,
    gradesEnabled,
  };
});
