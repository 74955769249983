<template>
  <MeetingModule>
    <c-input
      v-model="model.message"
      type="textarea"
      autogrow
      label="Instructie"
      placeholder="Bijv: Beste collega, de bespreking is geopend! Zie de tijdlijn voor belangrijke data. Ik zal zorgen voor een broodje kroket tijdens de speeddates. Veel succes met invullen! Groetjes, Corry Coördinator."
      lazy-rules
      :rules="[
        (val) => !!val || 'Instructie is verplicht',
        (val) =>
          val.length >= 20 ||
          'De instructie moet minimaal 20 karakters bevatten',
      ]"
      hide-bottom-space
      @update:model-value="modelUpdate"
    />
  </MeetingModule>
</template>

<script setup>
import { onMounted } from "vue";
import MeetingModule from "components/meeting/modules/MeetingModule.vue";

// Define the model
const model = defineModel({
  required: true,
  type: Object,
});

const isStepCompleted = defineModel("isStepCompleted");

// Update model value
const modelUpdate = (value) => {
  isStepCompleted.value = value && value.length >= 20;
};

onMounted(() => {
  // Set a default value for the model when it's empty
  if (model.value?.message == null) {
    model.value.message = "";
  }

  // Check the model onMounted
  modelUpdate(model.value.message);
});
</script>
